<script setup>
import { useMyStore } from "@/store/home";

const store = useMyStore();

</script>

<template>

  <!--  Partners -->
  <div class="container my-5">
    <div class="text-center">
      <h2 class="text-muted">OUR
        <span
            style="border-bottom: 2px solid green; padding-bottom: 10px;">
        PAR</span>TNERS
      </h2><br>
    </div>

    <div class="row justify-content-center text-center">
      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
               v-lazy="{ src: '/img/partners/ab.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
               class="img-fluid my-auto"
               alt="ab"
          >
          <img
              v-else
              src="/img/partners/ab.png"
              class="img-fluid my-auto"
              alt="ab"
          >

        </div>

      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
               v-lazy="{ src: '/img/partners/pbc.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
               alt="pbc" class="img-fluid my-auto"
          >
          <img
              v-else
              src="/img/partners/pbc.png"
              alt="pbc" class="img-fluid my-auto"
          >
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/pan_african.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="pan_african" class="img-fluid my-auto"
          >
          <img
              v-else
              src="/img/partners/pan_african.png"
              alt="pan_african" class="img-fluid my-auto"
          >
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/rmg.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="rmg" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/rmg.png"
              alt="rmg" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
               v-lazy="{ src: '/img/partners/agro.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
               alt="agro" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/agro.png"
              alt="agro" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/kampani.jpg', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="kampani" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/kampani.jpg"
              alt="kampani" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/fidelity.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Fidelity" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/fidelity.png"
              alt="Fidelity" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/glover.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Glover" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/glover.png"
              alt="Glover" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/adama.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Adama" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/adama.png"
              alt="Adama" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/sinapi.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Sinapi" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/sinapi.png"
              alt="Sinapi" class="img-fluid my-auto">
        </div>
      </div>.

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/advans.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Advans" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/advans.png"
              alt="Advans" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/gtco.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Gtco" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/gtco.png"
              alt="Gtco" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/amenfiman.jpg', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Amenfiman" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/amenfiman.jpg"
              alt="Amenfiman" class="img-fluid my-auto">
        </div>
      </div>

      <div class="col-3 col-lg-2 mb-3">
        <div class="card h-100 px-2">
          <img
              v-if="store.homeLazy"
              v-lazy="{ src: '/img/partners/absa.png', loading: '/img/dummy493x168.svg', error: '', delay: 500 }"
              alt="Absa" class="img-fluid my-auto">
          <img
              v-else
              src="/img/partners/absa.png"
              alt="Absa" class="img-fluid my-auto">
        </div>
      </div>

    </div>
  </div>


</template>

<style scoped>

</style>