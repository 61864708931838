<script setup>

</script>

<template>
<div class="my-5">
  <div class="container mt-4">
    <div class="row py-5">
      <div class="col-md-8">
        <h5 class="text-warning text-center">News</h5>
        <h1 class="text-center">Greenfield Receives $500K long term Capex loan from Kampani</h1>
        <small><span class="pi pi-clock"></span>
          November, 2022
        </small><br><br>
        <img src="/img/carousel/c1.jpeg" alt="banner" class="img-fluid mb-4">
        <p>
          In November, 2022, Greenfield received a long term subordinated Capex
          facility of $500k for 7 years from Kampani, a social impact investment organization
          from Belgium. The purpose of the facility is to purchase Trucks, Construct a
          Warehouse and purchase Spraying machines.
        </p>
        <p>
          Kampani’s investment will help Greenfield to reach out to over 25,000 smallholder
          farmers within the next 5 years. The purchase of new Pick-Ups will help increase
          Greenfield’s transport and distribution capacity and reach out to more smallholder
          farmers in the villages to increase its farmer base.
        </p>
        <p>
          The construction of the warehouse will also help Greenfield to better manage input
          stocks affected by price fluctuation. The purchase of spraying machines will also
          help provide a new service and additional income stream to Greenfield by
          renting the machines to farmers who cannot afford to buy them.
        </p>
      </div>
      <div class="col-md-4">
        <hr>
        <h6>Top Stories</h6>
        <hr>
        <br>
        <div class="card p-3 shadow">
          <h6 class="news-headings">Greenfield Receives $500K long term Capex loan from Kampani</h6>
          <small><span class="pi pi-clock"></span>
            November, 2022
          </small><br><br>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.news-headings {
  cursor: pointer;
}
.news-headings:hover {
  color: red;
}
</style>